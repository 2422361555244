import { render, staticRenderFns } from "./transactionsEmptyState.vue?vue&type=template&id=5590e631&scoped=true&"
import script from "./transactionsEmptyState.vue?vue&type=script&lang=js&"
export * from "./transactionsEmptyState.vue?vue&type=script&lang=js&"
import style0 from "./transactionsEmptyState.vue?vue&type=style&index=0&id=5590e631&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../funrize/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5590e631",
  null
  
)

export default component.exports