//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'transactions-header',
  props: {
    modeTypes: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleMode: function toggleMode(modeType) {
      this.$emit('mode-changed', modeType);
    }
  }
};