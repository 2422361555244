//
//
//
//
//
//
//
//
//
//
//
//
//
import { HISTORY_TYPE_INPUT, HISTORY_TYPE_OUTPUT } from "../../constants";
export default {
  name: 'transactions-empty-state',
  components: {
    'date-formatter': function dateFormatter() {
      return import(
      /* webpackChunkName: "date-formatter" */
      '@modules/cash/core/_utility/date-formatter');
    }
  },
  props: {
    selectedMode: {
      type: String,
      default: ''
    }
  },
  computed: {
    text: function text() {
      if (this.selectedMode === HISTORY_TYPE_INPUT) return 'You have no completed Purchases yet.';else if (this.selectedMode === HISTORY_TYPE_OUTPUT) return 'You have no Redeems yet.';
      return 'Please turn on one of the switchers to see results.';
    }
  }
};